export const POLYGON_RPCS = [
  "https://polygon-rpc.com",
  "https://polygon-bor-rpc.publicnode.com",
  "https://polygon.drpc.org"
];

export const POLYGON_AMOY_RPCS = [
  "https://rpc-amoy.polygon.technology",
  "https://polygon-amoy-bor-rpc.publicnode.com"
];
